.table-actions-wrapper {
  position: relative;
}

.table-actions {
  text-align: right;
  position: absolute;
  justify-content: flex-end;
  align-items: center;
  right: -2px;
  top: -2px;
  display: table;
  table-layout: fixed;
  display: none;
  padding-right: 5px;
  padding-left: 30px;
  row-gap: 5px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7f7f7+0,f7f7f7+18,f7f7f7+100&0+0,1+18,1+99 */
  background: -moz-linear-gradient(
    left,
    rgba(49, 56, 68, 0) 0%,
    rgba(49, 56, 68, 1) 18%,
    rgba(49, 56, 68, 1) 99%,
    rgba(49, 56, 68, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(49, 56, 68, 0) 0%,
    rgba(49, 56, 68, 1) 18%,
    rgba(49, 56, 68, 1) 99%,
    rgba(49, 56, 68, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(49, 56, 68, 0) 0%,
    rgba(49, 56, 68, 1) 18%,
    rgba(49, 56, 68, 1) 99%,
    rgba(49, 56, 68, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

%table-actions-base {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #0895df;
  border: 1px solid darken(#0895df, 10%);
  color: #fff;
  overflow: hidden;
  width: 28px;
  height: 28px;
  position: relative;
  cursor: pointer;

  svg {
    transform: translate(0, -1px);
  }

  &:hover {
    background: darken(#0895df, 10%);
  }
}

.table-actions-delete {
  @extend %table-actions-base;
  margin-left: 5px;
}

.table-actions-edit {
  @extend %table-actions-base;
  margin-left: 5px;
}

.table-actions-disable {
  @extend %table-actions-base;
  margin-left: 5px;
}

.table tbody tr:hover .table-actions {
  display: flex;
}

.table tbody tr:hover td {
  background-color: #313844;
}
