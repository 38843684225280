.dropdown {
  .dropdown-menu {
    background: #313844;
    border: 2px solid #3a4250;
    & > li {
      border-bottom: 1px solid #3a4250;
    }
  }

  .dropdown-item {
    color: #d1d5d6;

    &:hover,
    &:focus {
      background: #3a4250;
      color: #d1d5d6;
    }
  }
}
