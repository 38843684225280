.group-box {
  background-color: #313844;
  border-radius: 5px;
  border-bottom: 4px solid #313844;
  border-right: 4px solid #313844;
}

.group-box__content {
}

.group-box__header-summary--item {
  border-right: 2px solid #3a4250;
  &:last-of-type {
    border-right: none;
  }
}

.group-box__header-summary--item-title {
  font-size: 0.8rem;
}
