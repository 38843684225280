.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  top: -23px;
}

.loader {
  color: #2777ac;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &.white {
    color: #fff;
  }

  &.small {
    width: 1.5em;
    height: 1.5em;
    &:before,
    &:after {
      width: 1.5em;
      height: 1.5em;
    }
  }

  &.absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    transform: translate3d(-50%, -50%, 0);
  }
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

.loader.small:before {
  left: -2.5em;
}

.loader.small:after {
  left: 2.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* Second loader */
.loader-circle {
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  position: absolute;
  right: 16px;
  top: 14px;
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #2777ac,
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.7),
      1.8em -1.8em 0 0em #2777ac,
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.7),
      2.5em 0em 0 0em #2777ac,
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.7),
      1.75em 1.75em 0 0em #2777ac,
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.7),
      0em 2.5em 0 0em #2777ac,
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.7),
      -1.8em 1.8em 0 0em #2777ac,
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.7),
      -2.6em 0em 0 0em #2777ac,
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.7),
      -1.8em -1.8em 0 0em #2777ac;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #2777ac,
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.7),
      1.8em -1.8em 0 0em #2777ac,
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.7),
      2.5em 0em 0 0em #2777ac,
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.7),
      1.75em 1.75em 0 0em #2777ac,
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.7),
      0em 2.5em 0 0em #2777ac,
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.2),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.7),
      -1.8em 1.8em 0 0em #2777ac,
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(39, 119, 172, 0.7),
      -2.6em 0em 0 0em #2777ac,
      -1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(39, 119, 172, 0.2),
      1.8em -1.8em 0 0em rgba(39, 119, 172, 0.2),
      2.5em 0em 0 0em rgba(39, 119, 172, 0.2),
      1.75em 1.75em 0 0em rgba(39, 119, 172, 0.2),
      0em 2.5em 0 0em rgba(39, 119, 172, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(39, 119, 172, 0.7),
      -1.8em -1.8em 0 0em #2777ac;
  }
}
