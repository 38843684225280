.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.bg-primary {
  background-color: #336699 !important;
}

.top-line {
  border-top: 1px solid #336699 !important;
}

.input-group-text {
  color: #000;
  background-color: lighten(#363e4b, 40%);
  border-color: #424c5c;
}

.delete-icon {
  /* Delete icon is hidden by default */
  &-hidden {
    opacity: 0;
  }
  &:disabled {
    opacity: 0.5;
    background-color: #ccc;
    cursor: not-allowed;
  }
  color: #fff;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0895df;
  border: 1px solid darken(#0895df, 10%);
  border-radius: 50%;
  color: #fff;
  overflow: hidden;
  width: 28px;
  height: 28px;
  position: relative;
  cursor: pointer;
}

/* Show delete icon on hover */
.hover-container:hover .delete-icon-hidden {
  opacity: 1;
}

.delete-icon:hover:not(:disabled) {
  background: darken(#0895df, 10%);
}
