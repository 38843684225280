.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: #353d4a;
  color: #d1d5d6;
  z-index: 10;
}

.top-navbar__logo-wrapper {
  width: 100px;
  height: 80px;
}

.top-navbar__logo-link {
  img {
    width: 40px;
  }
}

.top-navbar__right--settings {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  color: #d1d5d6;
  transition: all 0.1s ease-in;

  &:hover {
    color: $blue;
  }
}
