.side-tabs__buttons {
  min-width: 150px;
}

.side-tabs__link {
  color: #d1d5d6;
  display: block;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  border-left: 4px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover,
  &.active {
    background-color: #292e38;
    border-left: 4px solid #0895df;
    color: #f7f7f7;
  }
}

.side-tabs__content {
  padding: 1rem;
  background-color: #292e38;
}
